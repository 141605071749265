body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
  color: #333;
}

.referee-details-container {
  width: 0;
  overflow: hidden;
  background-color: #fff;
  border-left: 1px solid #ddd;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease-in-out;
  position: relative;
  overflow: auto;
}

.referee-details-container.open {
  width: 50%;
}

.referee-details-slide {
  padding: 24px;
  overflow-y: auto;
  height: 100%;
}

@keyframes slideIn {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey background */
  border-top: 8px solid #3498db; /* Blue spinner color */
  border-radius: 50%;
  width: 50px; /* Size of the spinner */
  height: 50px; /* Size of the spinner */
  animation: spin 1s linear infinite; /* Animation */
  margin: auto; /* Centering spinner */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
